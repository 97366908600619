import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchServiceTerm, fetchPrivateTerm, type TermType, type TermSelector } from '@biz/api'

type UseFunctionType = ({ selector }: { selector: TermSelector }) => UseQueryResult<TermType>
export const useTermQuery: UseFunctionType = ({ selector }) =>
  useQuery({
    queryKey: ['@biz/ui/terms/TermButton/queries/useTerm', selector],
    queryFn: () => ('PRIVATE_DATA' ? fetchPrivateTerm : fetchServiceTerm)().then((res) => res.data),
    enabled: Boolean(selector),
  })
