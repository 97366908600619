import { useState, useCallback } from 'react'
import { Tag, Form } from 'antd'
import { toast } from 'react-toastify'
import { nanoid } from 'nanoid'
import alertify from 'alertifyjs'

import { ReactComponent as CloseBoxIcon } from '@common/svg/closeBox.svg'

import { type GeoInfoType } from '@biz/api'
import { useCachedLocationListQuery, useCampaignStatus } from '@biz/query'
import { CampaignCaption, targetFormRegistry, UpdateConfirmModal } from '@biz/ui'

import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import { GeoListSelect } from './GeoListSelect'
import styles from './LocationSelector.module.scss'

type LocationInputProps = {
  value?: string[]
  onChange?: (v: string[]) => void
}
const LocationInput: React.FC<LocationInputProps> = ({ value, onChange }) => {
  const { isFinished, inOperation, isAdmin } = useCampaignStatus()
  const { update, dryUpdate, remove } = useCachedLocationListQuery()

  const tagList = value || []

  const [changeConfirmData, setChangeConfirmData] = useState<{
    oldContent: string
    newContent: string
    updateCallback: () => void
  } | null>(null)
  const checkBeforeChange = useCallback(
    (oldList: string[], newList: string[], updateCallback: () => void) => {
      if (!inOperation?.()) {
        updateCallback()
        return
      }
      setChangeConfirmData({
        oldContent: `지역: ${oldList.join(',')}`,
        newContent: `지역: ${newList.join(',')}`,
        updateCallback,
      })
    },
    [inOperation]
  )

  const doChange = useCallback(
    async (sel: GeoInfoType) => {
      if (tagList.includes(sel.name)) {
        toast.warn('이미 선택된 지역입니다.')
        return
      }
      checkBeforeChange(tagList, dryUpdate(sel), () => onChange?.(update(sel)))
    },
    [tagList]
  )

  const disabled = isFinished?.() || isAdmin?.() || false
  const doDelete = useCallback(
    (e: React.MouseEvent<HTMLElement>, sel: string) => {
      if (disabled || !tagList || tagList.length <= 1) {
        e.preventDefault()
        alertify.error('최소 1개 이상의 지역이 입력되어야 합니다.')
        return
      }
      const targetContent = tagList.filter((item) => item !== sel)
      checkBeforeChange(tagList, targetContent, () => {
        remove(sel)
        onChange?.(targetContent)
      })
    },
    [onChange, tagList, remove]
  )
  return (
    <div
      className={styles.container}
      style={{ opacity: disabled ? '0.5' : '1', pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <div className={styles.tags}>
        {tagList.map((n, i) => (
          <Tag
            key={nanoid()}
            closable={true}
            onClose={(e) => doDelete(e, n)}
            className={styles.tag}
            closeIcon={<CloseBoxIcon />}
          >
            {n}
          </Tag>
        ))}
      </div>
      <GeoListSelect onSelected={doChange} disabled={disabled} />
      <UpdateConfirmModal
        isOpen={changeConfirmData !== null}
        title={'캠페인타겟을 수정 하시겠습니까?'}
        onClose={() => setChangeConfirmData(null)}
        onConfirm={() => {
          changeConfirmData?.updateCallback()
          setChangeConfirmData(null)
        }}
        oldContent={changeConfirmData?.oldContent || ''}
        newContent={changeConfirmData?.newContent || ''}
      />
    </div>
  )
}

// Form.Item 인터페이스에 맞춤
export const LocationSelector: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  return (
    <div>
      <CampaignCaption tooltip={'해당 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.'}>
        {'위치'}
      </CampaignCaption>
      <Form.Item name={targetFormRegistry.LocationSelectorName} required>
        <LocationInput onChange={doUpdate} />
      </Form.Item>
    </div>
  )
}
