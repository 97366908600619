import { useQuery, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { fetchCheckList } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export type CheckListInfo = {
  categoryName: string
  list: {
    content: string
    isCommon: boolean
  }[]
} | null
type UseFunctionType = (isEnabled: boolean) => UseQueryResult<CheckListInfo>
export const useCheckListQuery: UseFunctionType = (isEnabled: boolean) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  console.log(campaignId, 'campaignId', isEnabled, Boolean(campaignId))
  return useQuery({
    queryKey: ['@apps/biz/TargetCreatePage/CheckList'],
    queryFn: () =>
      !campaignId
        ? Promise.resolve(null)
        : fetchCheckList(campaignId).then((res) => {
            const { ok, data } = res || {}
            if (
              !ok ||
              _.isEmpty(data) ||
              _.isEmpty(data?.creationCheckData) ||
              _.isEmpty(JSON.parse(data.creationCheckData))
            ) {
              return null
            }
            return {
              categoryName: data.catCodeDesc,
              list: JSON.parse(data.creationCheckData),
            }
          }),
    enabled: isEnabled && Boolean(campaignId),
  })
}
