import { useState, useMemo } from 'react'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'

import { type GeoInfoType } from '@biz/api'
import { useLocationListQuery } from '@biz/query'

import styles from './GeoListSelect.module.scss'

type GeoListSelectFunction = {
  onSelected: (sel: GeoInfoType) => void
  disabled: boolean
}
export const GeoListSelect: React.FC<GeoListSelectFunction> = ({ onSelected, disabled }) => {
  const [keyword, setKeyword] = useState('')
  const debounceKeyword = useMemo(() => debounce(setKeyword, 400), [])
  const { data: locationList, isLoading } = useLocationListQuery({ keyword })
  const options = ((locationList as GeoInfoType[]) || []).map((n) => ({ label: n.name, value: n.key }))
  return (
    <div className={styles.containGeoListSelect}>
      <div className={styles.icon} />
      <Select
        showSearch
        filterOption={false}
        className={styles.searchBox}
        notFoundContent={
          isLoading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#00b2b2' }} spin />} size="small" />
          ) : null
        }
        placeholder={'지역을 검색하여 추가해 주세요'}
        style={{ height: 40 }}
        options={options}
        onSearch={debounceKeyword}
        value={''}
        onChange={(sel: string) => {
          const selectedItem = (locationList as GeoInfoType[])?.find((n) => n.key === sel)
          // type guard
          selectedItem && onSelected(selectedItem)
        }}
        disabled={disabled}
      />
    </div>
  )
}
